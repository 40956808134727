import Vue from 'vue';
import 'normalize.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import i18n from '@/lang/index';

Vue.config.productionTip = false;

async function loadmodules1() {
    const { appConfigModule } = await import('@/store/modules/appConfig');
    await appConfigModule.setAppConfig();
}

async function loadmodules2() {
    await import('@/permission');
    const router = await import('@/router/index');
    const store = await import('@/store');
    const App = await import('@/App.vue');
    const YwhzComponents = await import('@/utils/install');

    try {
        Vue.use(ElementUI, {
            zIndex: 3000,
            i18n: (key, value) => i18n.t(key, value)
        });
        Vue.use(YwhzComponents.default, {
            componentPrefix: 'Ywhz'
        });

        new Vue({
            router: router.default,
            store: store.default,
            i18n,
            render: (h) => h(App.default)
        }).$mount('#app');

    } catch (err) {
        console.warn(err);
    }

}

async function readModules() {
    await loadmodules1();
    await loadmodules2();
}
readModules();
